import React, { forwardRef } from "react";
import Phone from "../asset/Gossip/phone.png";
import Work from "../data/project.json";

import FlipImage from "./FlipImage";
import { Link } from "react-router-dom";

const Works = forwardRef(({ onBackClick }, ref) => {
  return (
    <div
      ref={ref}
      onClick={onBackClick}
      className="w-[90%] lg:w-[70%] min-h-screen ml-auto mr-auto"
    >
      <div className="flex  items-center">
        <h2 className="text-left text-white font-preahvihear text-[20px] sm:text-[20px] md:text-[28px]">
          Experience
        </h2>

        <FlipImage img={Phone} />
      </div>
      <div className="flex flex-col md:flex-row flex-wrap  items-center md:items-start md:justify-between">
        {Work?.project?.map((item, index) => (
          <Link
            to={`/detail/${item.id}`}
            className={`w-[90%] shadow-md scale-100 hover:scale-110 duration-1000 ${"md:w-[45%] md:h-[300px]"} border-2 border-[#2C1250] bg-[#f3f3f3] my-10 rounded-lg`}
          >
            <img className="md:h-[90%] object-contain" src={item.img}></img>
            <p className="font-semibold font-mono text-center my-2 text-[14px] sm:text-[16px]md:text-[18px] ">
              {item.title}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
});

export default Works;
