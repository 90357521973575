import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const NavBar = ({ header, executeScroll, expRef, workRef, contactRef }) => {
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const [winHeight, setWinHeight] = useState(window.scrollY);
  const [showNavBar, setShowNavBar] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWinWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWinWidth(0);
      });
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setWinHeight(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setWinHeight(0);
      });
    };
  }, []);

  const handleShowNavBar = () => {
    setShowNavBar(!showNavBar);
  };

  return (
    <div className="w-full bg-[#1A0B2E]">
      <div
        className={`bg-[#1A0B2E] w-max p-0.5 pb-2 mx-5 ${
          winWidth < 768 ? "flex self-start" : "hidden"
        }`}
      >
        <h2 onClick={handleShowNavBar} className={`font-mono text-[14px]  text-white p-1`}>
          BINYAM EDMEALEM
        </h2>
      </div>

      <motion.div
        initial={{
          x: -500,
          opacity: 0,
        }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          duration: 1.5,
        }}
        className={`flex justify-between w-[100%] items-center ${
          winWidth < 768 ? "bg-[#1A0B2E]" : "bg-none"
        } ${winHeight > 70 ? "fixed z-[1] bg-[#1A0B2E] w-[100%] top-0" : ""}`}
      >
        <div
          className={`w-max  ${
            winWidth < 768 ? "hidden" : "block "
          } p-0.5 pb-2 md:mx-5`}
        >
          <h2
            className={`font-mono text-white p-1 ${
              winWidth < 768 ? "hidden" : "block "
            }`}
          >
            BINYAM EDMEALEM
          </h2>
        </div>

        <div
          className={`flex flex-row justify-center w-[50%] ${
            winWidth < 768
              ? `${!showNavBar ? "hidden" : "block bg-[#1A0B2E] "} flex-col`
              : `block flex-row`
          }`}
        >
          <p
            onClick={() => executeScroll(header)}
            className="m-5 cursor-pointer text-white font-semibold text-[14px]  hover:text-slate-300  p-2 rounded-md "
          >
            {"<Home/>"}
          </p>
          <p
            onClick={() => executeScroll(expRef)}
            className="m-5 cursor-pointer text-white font-semibold text-[14px]  hover:text-slate-300  p-2 rounded-md "
          >
            {"<Expertise/>"}
          </p>
          <p
            onClick={() => executeScroll(workRef)}
            className="m-5 cursor-pointer text-white font-semibold text-[14px]  hover:text-slate-300  p-2 rounded-md "
          >
            {"<Work/>"}
          </p>
          <p
            onClick={() => executeScroll(contactRef)}
            className="m-5 cursor-pointer text-white font-semibold text-[14px]  hover:text-slate-300  p-2 rounded-md "
          >
            {"<Contact/>"}
          </p>
        </div>
        <div></div>
      </motion.div>
    </div>
  );
};

export default NavBar;
