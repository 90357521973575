import React, { useRef } from "react";
import Skill from "../asset/Skills.png";
import { forwardRef } from "react";
import { motion } from "framer-motion";
import { FaAws, FaDatabase, FaGitAlt, FaNodeJs, FaReact } from "react-icons/fa";
import { TbBrandReactNative } from "react-icons/tb";

const Expertise = forwardRef(({ onBackClick }, ref) => {
  const expertise = [
    {
      id: 1,
      name: "Frontend Dev",
      image: <FaReact color="white" className="text-[20px] md:text-[30px]" />,
      description:
        "Experienced in developing websites using React JS library, React-Bootstrap, Tailwind CSS, Redux, React-Router.",
    },
    {
      id: 2,
      name: "Backend Development",
      image: <FaNodeJs color="white" className="text-[20px] md:text-[30px]" />,
      description:
        "Skilled in developing fast, scalable ,and realtime backend using Node JS and Express.",
    },
    {
      id: 3,
      name: "Cross-Platform Mobile App",
      image: (
        <TbBrandReactNative
          color="white"
          className="text-[20px] md:text-[30px]"
        />
      ),
      description:
        "Develop cross-platform mobile application using React Native,Native modules, and notification.",
    },
    {
      id: 4,
      name: "Database",
      image: (
        <FaDatabase color="white" className="text-[20px] md:text-[30px]" />
      ),
      description:
        "Experienced in developing relational database using MySQL and non-relational database like MongoDB.",
    },
    {
      id: 5,
      name: "Version Control",
      image: <FaGitAlt color="white" className="text-[20px] md:text-[30px]" />,
      description:
        "Git for version control. GitHub and BitBucket for remote repository.",
    },
    {
      id: 6,
      name: "DevOps",
      image: <FaAws color="white" className="text-[20px] md:text-[30px]" />,
      description:
        "Use Docker, K8, CICD to deploy apps on AWS EC2, digitalocean droplet or other cloud service provider platform for deployment, server configuration, and backup.",
    },
  ];
  return (
    <div
      ref={ref}
      onClick={onBackClick}
      className="min-h-screen self-center w-full md:w-[70%] mt-60 px-10 md:px-0"
    >
      <h2 className="text-left text-white font-preahvihear text-[20px] sm:text-[20px] md:text-[28px]">
        Skill
      </h2>
      <div className="flex ml-auto mr-auto justify-between mt-10 flex-wrap">
        {expertise.map((item, index) => (
          <div className="flex items-center justify-between bg-[#2C1250] border-t-2 border-[#693B93] w-full md:w-[45%] p-10 m-1 bg-gradient-to-r from-[#11071F] via-[#2f1b4d] to-[#11071F] rounded-lg shadow-lg space-x-4">
            <div className="flex-0.1 md:flex-0.3">{item.image}</div>
            <div className="flex-0.9 md:flex-0.7">
              <p className="text-[#ffffff] font-semibold text-[18px] sm:text-[20px] mx-2">
                {item.name}
              </p>
              <p className="text-slate-400 font-preahvihear text-[13px] mx-2">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex mt-5 justify-center">

      <img src={Skill}></img>
      </div>
    </div>
  );
});

export default Expertise;
