import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProjectData from "../data/project.json";
import NavBar from "./NavBar";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Detail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [projects, setProjects] = useState({});

  useEffect(() => {
    let temp = ProjectData?.project?.find((items) => items.id == params?.id);
    setProjects(temp);
  }, []);

  const executeScroll = () => {
    navigate("/");
  };
  return (
    <div className="mb-5 bg-[#11071F] min-h-screen">
      <div className="h-[60px]">
        <NavBar executeScroll={executeScroll} />
      </div>
      <div className="flex  flex-col px-[10%]">
        <h4 className="text-[white] self-end mt-10 font-serif shadow-md text-sm md:text-md lg:text-2xl animate-bounce rounded-sm px-2 shadow-[#2d2843] w-max">
          {projects?.type}
        </h4>
        <h2 className="text-white text:2xl md:text-3xl lg:text-4xl font-preahvihear font-semibold">
          {projects?.title}
        </h2>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-[60%]">
            <p className="text-slate-400">{"<h3>"}</p>
            <p className="text-[14px] text-white px-2 border-l-slate-500 border-l-4 sm:text-[14px] md:text-[16px] font-preahvihear">
              {projects.description}
            </p>
            <p className="text-slate-400">{"<h3>"}</p>
            <p>
              {projects?.url ? (
                <a
                  className="text-[white] font-mono bg-yellow-500 rounded-md px-2.5 py-1.5 my-1"
                  href={projects.url}
                  target="_blank"
                >
                  Visit
                </a>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="flex w-full lg:w-[50%] justify-between">
            <div className="w-[45%]">
              <p className="text-white text-[14px] px-2 border-b-[#85144B] border-b-2 sm:text-[14px] md:text-[16px] font-preahvihear">
                Client
              </p>
              <p className="text-white  text-[14px] px-2  md:text-[16px] font-preahvihear">
                {projects.client}
              </p>
            </div>
            <div className="w-[45%]">
              <p className=" text-white text-[14px] px-2 border-b-[#85144B] border-b-2 sm:text-[16px] md:text-[18px] font-mono">
                Technology
              </p>
              <p className="text-white  text-[14px] px-2 sm:text-[14px] md:text-[14px] font-preahvihear">
                {projects?.technology?.map((items) => (
                  <p>{items}</p>
                ))}
              </p>
            </div>
          </div>
        </div>
        {/* <img
          src={projects?.img}
          className="w-[90%] md:w-[90%] lg:w-[80%] self-center "
        /> */}
      </div>
      <Carousel showThumbs={false}>
        {projects.imageArray?.map((img) => (
          <img
            src={img}
            className="w-[100%] h-[400px] my-2  object-scale-down"
          />
        ))}
      </Carousel>
    </div>
  );
};

export default Detail;
