import React, { useRef } from "react";
import Header from "./Header";
import Expertise from "./Expertise";
import Works from "./Works";
import Contact from "./Contact";

const MainPage = ({ executeScroll }) => {
  const expRef = useRef(null);
  const workRef = useRef(null);
  const contactRef = useRef(null);
  const header = useRef(null);

  return (
    <div className="flex flex-col">
      <Header
        expRef={expRef}
        workRef={workRef}
        contactRef={contactRef}
        header={header}
        executeScroll={executeScroll}
      />
      <Expertise ref={expRef} onBackClick={executeScroll} />
      <Works ref={workRef} onBackClick={executeScroll} />
      <Contact ref={contactRef} onBackClick={executeScroll} />
    </div>
  );
};

export default MainPage;
