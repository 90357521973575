import "./App.css";
import Typewriter from "typewriter-effect";
import { Route, Routes, useNavigate } from "react-router-dom";
import Detail from "./components/Detail";
import MainPage from "./components/MainPage";

function App() {
  const executeScroll = (ref) => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  return (
    <div className="w-[100%] bg-[#11071F]">
      <Routes>
        <Route
          path="/"
          element={<MainPage executeScroll={executeScroll} />}
        ></Route>
        <Route path="/detail/:id" element={<Detail />}></Route>
      </Routes>
    </div>
  );
}

export default App;
