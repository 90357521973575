import React, { forwardRef } from "react";

import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import MailIcon from "@mui/icons-material/Mail";

import Ethiopia from "../asset/ethiopia.png";
import ReactImg from "../asset/react.png";
import ReactNative from "../asset/RN.png";
import Node from "../asset/node.png";
import MySQL from "../asset/mysql.png";
import { IconButton, Tooltip } from "@mui/material";

const Contact = forwardRef(({ onBackClick }, ref) => {
  return (
    <div
      className="flex flex-col bg-[#1A0B2E] mt-5 w-[100%] min-h-[250px] justify-center items-center"
      ref={ref}
      onClick={onBackClick}
    >
      <div className="w-[100%] flex flex-col sm:flex-row items-center justify-center sm:justify-between">
        <div className="hidden sm:flex sm:flex-col text-slate-400 mx-4 text-[20px]">
          <img
            src={ReactImg}
            style={{ width: 40, height: 30 }}
            className="m-2 border-l-2 border-slate-500"
          ></img>
          <img
            src={ReactNative}
            style={{ width: 40, height: 30 }}
            className="m-2 border-l-2 border-slate-500"
          ></img>
          <img
            src={Node}
            style={{ width: 40, height: 30 }}
            className="m-2 border-l-2 border-slate-500"
          ></img>
          <img
            src={MySQL}
            style={{ width: 40, height: 30 }}
            className="m-2 border-l-2 border-slate-500"
          ></img>
        </div>
        <div>
          <p className="text-white text-[20px] text-center">LET'S CONNECT </p>
          <a target="_blank" href="https://github.com/bini-ed">
            <GitHubIcon
              className="m-[10px] hover:text-purple-400 text-white "
              fontSize="medium"
            />
          </a>

          <a target="_blank" href="tel:+251923796087">
            <PhoneIcon
              className="m-[10px] hover:text-purple-400 text-white "
              fontSize="medium"
            />
          </a>

          <a
            target="_blank"
            href="https://www.linkedin.com/in/binyam-edmealem-7a0571228/"
          >
            <LinkedInIcon
              className="m-[10px] hover:text-purple-400 text-white "
              fontSize="medium"
            />
          </a>
          <a target="_blank" href="https://t.me/bini_ed">
            <TelegramIcon
              className="m-[10px] hover:text-purple-400 text-white "
              fontSize="medium"
            />
          </a>
          <Tooltip
            title={<h3 className="text-lg mx-1">binyamedmealem@gmail.com</h3>}
          >
            <IconButton>
              <MailIcon
                className="m-[10px] hover:text-purple-400 text-white "
                fontSize="medium"
              />
            </IconButton>
          </Tooltip>
        </div>
        <img
          src={Ethiopia}
          className="m-2"
          style={{ width: 80, height: 80 }}
        ></img>
      </div>
    </div>
  );
});

export default Contact;
