import React from "react";
import Me from "../asset/Me.png";
import Arrow from "../asset/Arrow.png";
import Typewriter from "typewriter-effect";
import Down from "../asset/down.png";
import { motion } from "framer-motion";
import NavBar from "./NavBar";

const Header = ({ header, executeScroll, expRef, workRef, contactRef }) => {
  return (
    <div
      ref={header}
      className="flex flex-col w-[100%] justify-center items-center"
    >
      <NavBar
        header={header}
        executeScroll={executeScroll}
        expRef={expRef}
        workRef={workRef}
        contactRef={contactRef}
      />
      <div className="flex flex-col items-center justify-center w-[100%] mt-12 px-10">
        <p className="text-[white] w-full md:w-[70%] my-5 md:pl-[10%] font-mono text-[20px] md:text-[16px] xl:text-[18px]">
          Hello, I am <span className="text-[#7127BA]">Binyam Edmealem</span>
        </p>

        <div className="flex flex-col w-full md:w-[70%] md:flex-row items-center ">
          <img
            className="w-[70%] md:w-[20%] object-contain"
            src={Me}
            onClick={() => executeScroll(expRef)}
          ></img>

          <div className="flex flex-col w-[100%] justify-center ">
            <p className="mt-10 font-preahvihear text-slate-400  font-thin  text-left  text-[12px] md:text-[12px] lg:text-[14px]">
              A Full Stack Developer
            </p>
            <p className="font-preahvihear text-white text-left font-semibold   text-[18px] md:text-[18px] lg:text-[26px]">
              With experience on <br></br>web and mobile{" "}
              <span className="text-[#7127BA]">application</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-[100%] md:w-[70%] md:justify-start  md:items-start  md:flex-row mt-12 px-10 md:px-0">
        <div className="flex flex-col text-[white] font-preahvihear text-[18px] md:text-[19px] xl:text-[22px]">
          <p>I am a Software Engineer!</p>
          <p className="font-preahvihear text-slate-400  font-thin   text-[12px] md:text-[12px] lg:text-[14px]">
            Currently, I am a software engineer at Safaricom Ethiopia
          </p>
          <p className="font-preahvihear text-slate-400  font-thin mt-10  text-[12px] md:text-[12px] lg:text-[14px]">
            A full-stack software developer who has education, and experience in
            building a variety of applications and services for the past 4 years
            by utilizing Node JS, ReactJS, Next JS, MySQL, PostgreSQL,
            Docker, CICD, and Kubernetes.
          </p>
        </div>
      </div>
      {/* <div className="relative">
        <div className="absolute -inset-1.5 bg-[#533c74]   blur rounded-lg"></div>
        <img
          src={Down}
          onClick={() => executeScroll(expRef)}
          className="cursor-pointer animate-bounce bg-[#1A0B2E] border-white border-2 rounded-md p-2"
          style={{ width: 50, height: 50 }}
        ></img>
      </div> */}
    </div>
  );
};

export default Header;
