import { motion } from "framer-motion";

function FlipImage({ img }) {
  return (
    <motion.img
      transition={{
        duration: 5.5,
      }}
      animate={{ x: [0, 200, 200, 0], y: [0, 100, 0, 0] }}
      src={img}
      className="max-h-[300px] lg:max-h-[450px] w-[200px] sm:w-[100px] md:w-[200px] lg:max-w-[350px] object-contain min-h-full"
    />
  );
}

export default FlipImage;
